<template>
  <div class="tab-pane active" id="plans">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <router-link
            :to="{ name: 'AttachPlan', params: { id: customer.id } }"
            class="btn btn-success btn-floating"
          >
            <i
              class="
                fas
                fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <data-table
            :columns="[
              {
                key: 'service',
                class: 'badge badge-primary',
                label: 'product',
              },
              'name',
              {
                key: 'pivot.pricing.fee',
                label: 'price',
                filters: ['currency'],
              },
            ]"
            :data="plans"
          >
            <template #actions="item">
              <dropdown>
                <template v-slot:text>
                  <i class="fas fa-ellipsis-h"></i>
                </template>
                <template v-slot:items>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="destroyPlan(item.id)"
                  >
                    {{ $t('app.cancel') }}
                  </a>
                </template>
              </dropdown>
            </template>
          </data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Tab from '@/directives/Tab';
import DataTable from '@/components/DataTable';
import Dropdown from '@/components/Dropdown';
import { mask } from 'vue-the-mask';

export default {
  name: 'CustomerPlans',
  components: {
    DataTable,
    Dropdown,
  },
  directives: {
    Tab,
    mask,
  },
  props: ['customer'],
  data() {
    return {
      loading: false,
      isSending: false,
      plans: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      pages: 1,
    };
  },
  created() {
    this.fetchPlans();
  },
  methods: {
    fetchPlans() {
      this.$store
        .dispatch('fetchAdminPlans', {
          account_id: this.$route.params.customerId,
          page: 1,
        })
        .then((response) => {
          this.plans = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    destroyPlan(planId) {
      this.loading = true;
      Swal.fire({
        title: this.$t('admin.plan.cancel'),
        text: this.$t('admin.plan.cancel-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch('detachPlan', {
              account_id: this.$route.params.customerId,
              plan_id: planId,
            })
            .then((response) => {
              this.fetchPlans();
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
.select-container {
  display: block;
}
</style>
